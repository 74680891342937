/*********************CONTAINER***************************/
.footer-container {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 80px;
    background: linear-gradient(115deg, #fdbf0a, #000000 35%);
}
/****************************************************/

/*********************CONTENT***************************/

.footer-content {
    color: white;
    text-align: center;
    width: 100%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-link {
    text-decoration: none;
    color: white;
}

.footer-icons {
    padding-right: 30px;
}

.footer-linkedin-icon {
    font-size: 25px;
    padding-right: 10px;
    cursor:pointer;
}

.footer-github-icon {
    font-size: 25px;
    cursor:pointer;
}
/****************************************************/

/*********************LOGO***************************/

.footer-logo-jb {
    width: 40px;
    cursor:pointer;
}

/****************************************************/

/*********************RESPONSIVE***************************/

@media screen and (max-width: 767px) {
/*
    .footer-logo-jb {
        width: 55px;
    }*/
}

/****************************************************/
