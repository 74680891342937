.button {
    border: inherit;
    border-radius: 6px;
    box-shadow: 0.05em 0.05em 0.05em 0.05em #3f3838;
    padding: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2b62a6;
    cursor: pointer;
    width: 100px;
  }
  
  .button:active {
    box-shadow: 0.05em 0.05em 0.05em 0.05em #b6a9a9;
    filter: brightness(130%);
  }
  
  .icon-button {
    margin-right: 5px;
  }
  