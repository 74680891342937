.portfolio-container {
    background-color: white;
    width: 100%;
    padding: 6rem 0 10rem 0;
}

.portfolio-big-title {
    color: #2b62a6;
    font-size: 2rem;
    text-shadow: 0 5px 5px rgba(97, 135, 172, 0.2);
}

.portfolio-small-title {
    color: #fdbf0a;
    font-size: 1.3rem;
    text-shadow: 0 5px 5px rgba(97, 135, 172, 0.2); 
}

.portfolio-content {
    margin-top: 3em;
}



