.home-container {
    display: flex;
    align-items: center;
    height: 100vh;
background-image: url("../../assets/laptop-desktop.jpeg");
background-size: cover;
background-position: center;
background-repeat: no-repeat;
position: relative;
}

.home-title-container {
    width: 90%;
    text-align: center;
  position: absolute;
  top: 23%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.home-big-title {
    font-size: 2.5rem;
}

.home-title {
    font-size: 2.2rem;
    margin-bottom: 1em;
}

.home-icon-title {
    color: #fdbf0a;
}

.home-text {
    font-size: 2rem;
    color: #fdc00b;
}

.home-slash {
    color: white;
}

.home-picture-desktop {
    height: 100vh;
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .home-big-title {
        font-size: 2rem;
    }

    .home-title {
        font-size: 1.8rem;
    }

    .home-text {
        font-size: 1.5rem;
    }
}


@media screen and  (max-width: 767px) {
    .home-container {
        background-image: url("../../assets/laptop-phone.jpeg");
    }

    .home-title-container {
        width: 80%;
    }

    .home-big-title {
        font-size: 1.7rem;
    }

    .home-title {
        font-size: 1.5rem;
    }

    .home-text {
        font-size: 1.3rem;
    }
}
