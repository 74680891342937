@import url('https://fonts.googleapis.com/css2?family=Kanit:ital@1&family=Poppins&display=swap');


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
}

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-size: 1.1rem;
    line-height: 1.3;
}

.main-container {
    text-align: center;
    min-height: calc(300vh);
    position: relative;
    overflow: hidden;
}

.main {
    min-height: 200vh;
    /*background-color: #dcf5fc;*/
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Kanit", sans-serif;
}
