.slick-dots li button:before {
    font-size: 15px !important;
}

.caroussel-content-1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.caroussel-img {
    width: 50vw !important;
    height: 600px !important;
object-fit: cover;
}

.caroussel-link {
    font-weight: bold;
    color: black;
    text-decoration: none;
}

.cinefilms {
    color: #fcb400;
}

.medisimus {
    color: #f38f50;
}

.cryptowatch {
    color: #0fafa2;
}

.lifestories {
    color: #d31a39;
}

.ohmyfood {
    color: #d16adb;
}

.reservia {
    color: #0164fc;
}


@media screen and (max-width: 1574px) {
    .caroussel-img {
        width: 50vw !important;
        height: 550px !important;
        object-fit: cover;
    }
}

@media screen and (max-width: 1480px) {
    .caroussel-img {
        width: 50vw !important;
        height: 500px !important;
        object-fit: cover;
    }
}

@media screen and (max-width: 1024px) {
    .caroussel-img {
        width: 100vw !important;
        height: 450px !important;
        object-fit: cover;
    }
}

@media screen and (max-width: 767px) {
    .caroussel-img {
        width: 100vw !important;
        height: 400px !important;
        object-fit: cover;
    }

    .slick-dots li button:before {
        font-size: 10px !important;
    }
}

@media screen and (max-width: 450px) {
    .caroussel-img {
        height: 300px !important;
    }
}