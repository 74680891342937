
.card-skills-container {
    width: 48%;
    background: linear-gradient(-115deg, #fdbf0a, #000000 60%);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border-radius: 6px;
}

.card-skills-title {
    margin: 1em 0 1em;
    font-size: 1.6rem;
    color: white;
}

.card-skills-case {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    padding: 2em;
    width:90%;
    border-radius: 6px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    margin: 3em 0 3em;
}
.card-skills-container h4 {
    font-size: 1.5rem;
    margin:0.5em 0 1em;
    color: #fdbf0a;
}
.card-skills-front {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    padding: 2em;
}

.card-skills-logos-container-1 {
    display: flex;
    justify-content: center;

}

.card-skills-logos-container-2 {
    display: flex;
    justify-content: center;
}

.card-skills-logo-line {
    display: flex;
    width: 90%;
    justify-content: center;
    background-color: white;
    padding: 0.5em;
    margin:1em;
    border-radius: 6px;
}

.card-skills-logo-html {
    width:15%;
}
.card-skills-logo-css {
    width:10%;
}
.card-skills-logo-javascript {
    width:15%;
}
.card-skills-logo-react {
    width:15%;
}
.card-skills-logo-redux {
    width:15%;
}
.card-skills-logo-node {
    width:20%;
}
.card-skills-logo-mysql {
    width:20%;
}
.card-skills-logo-postman {
    width:30%;
}
.card-skills-logo-aws {
    width:20%;
}
.card-skills-logo-serverless {
    width:20%;
}
.card-skills-logo-git {
    width:15%;
}
.card-skills-logo-github {
    width:20%;
}

.card-skills-container:hover {
    background: linear-gradient(-115deg, #549eff, #001128 60%);
    filter: brightness(1.25);
    transition: all 0.4s ease-in-out;
    transform: scale(1.02);
}

.card-skills-container:hover .card-skills-case {
    filter: brightness(0.8);
}

@media screen and  (max-width: 1024px) {

    .card-skills-container {
        width: 95%;
    }

    .card-skills-case {
        padding: 1em;
        margin: 1em 0 1em;
    }
  }