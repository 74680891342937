.card-work-container {
    width: 48%;
    background: linear-gradient(115deg, #fdbf0a, #000000 60%);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border-radius: 6px;
}

.card-work-title {
    margin: 1em 0 1em;
    font-size: 1.6rem;
    color: white;
}

.card-work-content {
    width: 90%;
    color: white;
}

.card-work-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
}

.card-work-text h4 {
    font-size: 1.4rem;
    margin:1em 0 1em;
    color: black;
}

.card-work-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #000000;
    padding: 2em;
    width:100%;
    border-radius: 6px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    margin: 1em 0 1em;
}

.card-work-code-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.card-work-code-content h5 {
    font-size: 1.4rem;
    margin:0.5em 0 0.5em;
    color:#fdbf0a;
}

.card-work-icon {
    font-size: 2.5em;
}

.card-work-picture {
    margin: 1em 0 1em
}

.card-work-container:hover {
    background: linear-gradient(115deg, #549eff, #001128 60%);
    filter: brightness(1.25);
    transition: all 0.4s ease-in-out;
    transform: scale(1.02);
}

.card-work-container:hover .card-work-content {
    filter: brightness(0.8);
}
@media screen and  (max-width: 1024px) {

    .card-work-container {
        width: 95%;
        margin-bottom: 2em;
    }

    .card-work-code-container {
        padding: 1em;
    }
  }
