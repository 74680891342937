
.button-top {
    font-size: 3rem;
    height: 40px;
    width: 40px;
    background: #fdbf0a;
    border-radius: 6px;
    cursor: pointer;
    color: white;
    position: fixed;
    bottom: 10px;
    right: 10px;
    transition: all 0.3s ease;
}


.button-top:hover {
    transition-duration: 0.1s;
    background-color: #ffd460;
}
.button-top:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 6px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 10px 40px #edbea1;
}
.button-top:active:after {
    box-shadow: 0 0 0 0 #edbea1;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
}

@media (max-width: 767px) {
    .button-top {
        font-size: 2.8rem;
        height: 35px;
        width: 35px;
    }
    
}