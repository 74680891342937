
.services-container  {
   padding: 2rem 0 0 0;
}

.services-title {
    margin: 3em 0 2em;
    font-size: 2rem;
    text-shadow: 0 5px 5px rgba(97, 135, 172, 0.2);
    color: #fdbf0a;
}

.services-content-container {
    display: flex;
    justify-content: space-evenly;
}

@media screen and  (max-width: 1024px) {

    .services-content-container {
        flex-direction: column;
        align-items: center;
    }
  }