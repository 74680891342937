.presentation-container {
  padding: 2rem 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(115deg, #fdbf0a, #000000 60%);
}

.presentation-title{
  font-size: 2rem;
    color: white;
    margin-top: 3em;
}

.presentation-content-container {
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 5em;
    margin-bottom: 5em;
}

.presentation-picture {
    width: 25%;
}

.presentation-picture img  {
    border: 3px solid black;
    border-radius: 5px;
}

.presentation-text {
    display: flex;
    flex-direction: column;
    align-items: center;
width: 50%;
color: white;
}

.presentation-text-first {
  margin-bottom: 1em;
}

.presentation-text-titles {
  font-size: 1.4rem;
  margin: 1em 0 1em;
}

.presentation-text-second {
text-align: left;
}

.presentation-text-third {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
}

.presentation-link {
  margin-top: 1em;
  text-decoration: none;
}

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .presentation-content-container {
        width: 90%;
        flex-direction: column;
    }

    .presentation-picture {
        width: 45%;
        margin-bottom: 2em;
    }

    .presentation-text {
      width: 100%;
      }
}


@media screen and  (max-width: 767px) {

  .presentation-container {
    background: linear-gradient(110deg, #fdbf0a, #000000 50%);
  }
    .presentation-content-container {
        width: 90%;
        flex-direction: column;
    }

    .presentation-picture {
        width: 60%;
        margin-bottom: 2em;
    }

    .presentation-text {
  width: 100%;
  }
}