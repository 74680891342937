/*********************CONTAINER***************************/

.header-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 2;
    width: 100%;
    min-height: 75px;
    margin: auto;
    background: linear-gradient(115deg, #000000 60%, #fdbf0a);
    padding: 0.5em;
    
}
/****************************************************/

/*********************LOGO***************************/

.header-logo-jb {
    width: 60px;
    cursor: pointer;
}

/****************************************************/

/*********************LINKS***************************/

.header-links-container {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
}

/*.header-links-li {
    font-family: "Kanit", sans-serif;
}*/

.header-links-button {
    cursor: pointer;
    text-decoration: none;
    color: white;
    padding: 0 0.3rem;
    border: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 0.8em;
    font-weight: 800;
}
/****************************************************/

/*********************BURGER MENU***************************/

.navbar-burger {
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    color: inherit;
    display: none;
}
/****************************************************/

/*********************RESPONSIVE***************************/

@media screen and (max-width: 767px) {
    .header-container {
        padding: 0.65em;
    }

    .header-links-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        right: -100vw;
        bottom: 0;
        width: 0;
        height: 100vh;
        visibility: hidden;
        background-color: white;
        background-image: url(../../assets/fresque.jpg);
        background-size: cover;
        transition: all 0.8s ease-out;
    }
    .show-nav .header-links-container {
        width: 100vw;
        right: 0;
        visibility: visible;
    }

    .header-logo-jb {
        width: 55px;
    }

    .header-links-li::after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 3vw;
        height: 2px;
        background: #fdc00b;
    }
    .header-links-li:last-child::after {
        display: none;
    }

    .header-links-button {
        display: block;
        padding: 1.5rem;
        font-size: 1em;
    }
    .navbar-burger {
        display: block;
        position: fixed;
        top: 1rem;
        right: 1rem;
    }
    .navbar-burger:hover {
        cursor: pointer;
    }
    .burger-bar,
    .burger-bar::before,
    .burger-bar::after {
        display: block;
        width: 35px;
        height: 3px;
        position: relative;
        border-radius: 3px;
        background: white;
        transition: all 0.5s ease-in-out;
    }
    .burger-bar::before,
    .burger-bar::after {
        content: "";
        position: absolute;
        left: 0;
    }
    .burger-bar::before {
        transform: translateY(-12px);
    }
    .burger-bar::after {
        transform: translateY(12px);
    }
    .show-nav .burger-bar {
        width: 0;
        background: transparent;
    }
    .show-nav .burger-bar::before {
        transform: rotate(45deg);
    }
    .show-nav .burger-bar::after {
        transform: rotate(-45deg);
    }
    .header-links-li {
        transform: translateY(100vh);
    }
    .show-nav .header-links-li {
        transform: translateY(0);
    }
    .show-nav .slideInDown-1 {
        transition: all 1s ease-out;
    }
    .show-nav .slideInDown-2 {
        transition: all 1.1s ease-out;
    }
    .show-nav .slideInDown-3 {
        transition: all 1.2s ease-out;
    }
    .show-nav .slideInDown-4 {
        transition: all 1.3s ease-out;
    }
    .show-nav .slideInDown-5 {
        transition: all 1.4s ease-out;
    }
}
/****************************************************/