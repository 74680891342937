/*********************CONTAINER***************************/

.contact-container {
    color: white;
    background: linear-gradient(115deg, #000000 40%, #fdbf0a );
    padding: 8rem 0;
    z-index: 1;
}
/****************************************************/

/*********************TITLE***************************/

.contact-big-title {
font-size: 2rem;
}
/****************************************************/

/*********************ALL CONTENT***************************/

.contact-all-content {
    max-width: 1300px;
    padding: 0 80px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
/****************************************************/

/*********************LEFT CONTENT***************************/

.contact-left-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 30px;
}

.contact-left-text {
    margin-bottom: 20px;
}

.contact-name-jb {
    margin-right: 0.2em;
}

.contact-left-infos {
    width: 80%;
    display: flex;
    height: 55px;
    align-items: center;
    padding-left: 15px;
    border-radius: 6px;
    background: white;
    margin: 0.5rem 0 0.5rem 0;
    color: #000;
    box-shadow: 0 14px 28px rgba(97, 135, 172, 0.05),
        0 10px 10px rgba(97, 135, 172, 0.6);
}

.contact-left-icons {
    color: #0046a4;
    font-size: 20px;
    margin-right: 8px;
}


/****************************************************/

/*********************RIGHT CONTENT***************************/

.contact-right-content {
    width: 100%;
    margin-top: 30px;
}
/****************************************************/

/*********************RESPONSIVE***************************/

@media screen and (max-width: 767px) {
    .contact-all-content {
        padding: 0;
        width: 90%;
    }

    .contact-left-infos {
        width: 100%;
    }
}
/****************************************************/